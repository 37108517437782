// Imports => Constants
import { ENDPOINTS } from '@constants';

export class MyCompanyAPI {
  constructor(Client, UploadClient) {
    this.Client = Client;
    this.UploadClient = UploadClient;
  }

  getMyCompany = () => {
    return this.Client.get(ENDPOINTS.MY_COMPANY.GET).then(
      (response) => response.data.data
    );
  };

  updateMyCompany = (data) => {
    return this.Client.put(ENDPOINTS.MY_COMPANY.UPDATE, data).then(
      (response) => response.data.data
    );
  };

  addMyCompanyLogo = (data) => {
    return this.UploadClient.post(ENDPOINTS.MY_COMPANY.LOGO.ADD, data).then(
      (response) => response.data.data
    );
  };

  removeMyCompanyLogo = () => {
    return this.UploadClient.delete(ENDPOINTS.MY_COMPANY.LOGO.REMOVE).then(
      (response) => response.data.data
    );
  };
}

export default MyCompanyAPI;

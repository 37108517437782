// Imports => Constants
import { ENDPOINTS } from '@constants';

export class EquipmentAPI {
	constructor(Client, config) {
		this.Client = Client;
		this.config = config;
	}

	list = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT.LIST, { params }).then(
			(response) => response.data
		);
	};
}

export default EquipmentAPI;

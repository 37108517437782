// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ControlUnitTypesAPI {
	constructor(Client, UploadClient, config) {
		this.Client = Client;
		this.UploadClient = UploadClient;
		this.config = config;
	}

	list = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT_TYPES.CONTROL_UNITS.LIST, {
			params,
		}).then((response) => response.data);
	};

	table = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT_TYPES.CONTROL_UNITS.TABLE, {
			params,
		}).then((response) => response.data);
	};

	get_by_id = (id) => {
		return this.Client.get(
			ENDPOINTS.EQUIPMENT_TYPES.CONTROL_UNITS.GET_BY_ID(id)
		).then((response) => response.data.data);
	};

	store = (data) => {
		return this.Client.post(
			ENDPOINTS.EQUIPMENT_TYPES.CONTROL_UNITS.STORE,
			data
		).then((response) => response.data.data);
	};

	update = (id, data, options) => {
		const client =
			options && options.upload ? this.UploadClient.post : this.Client.put;

		return client(
			ENDPOINTS.EQUIPMENT_TYPES.CONTROL_UNITS.UPDATE(id),
			data
		).then((response) => response.data.data);
	};

	delete = (data) => {
		return this.Client.delete(
			ENDPOINTS.EQUIPMENT_TYPES.CONTROL_UNITS.DELETE(data)
		).then((response) => response);
	};

	delete_media = (data) => {
		return this.Client.delete(
			ENDPOINTS.EQUIPMENT_TYPES.CONTROL_UNITS.MEDIA(data)
		).then((response) => response.data.data);
	};

	get_software = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT_TYPES.CONTROL_UNITS.SOFTWARE, {
			params,
		}).then((response) => response.data.data);
	};

	upload_software_update = (data) => {
		return this.UploadClient.post(
			ENDPOINTS.EQUIPMENT_TYPES.CONTROL_UNITS.SOFTWARE,
			data
		).then((response) => response);
	};

	update_software_update = (id, data) => {
		return this.Client.put(
			ENDPOINTS.EQUIPMENT_TYPES.CONTROL_UNITS.UPDATE_SOFTWARE(id),
			data
		).then((response) => response);
	};

	download_software = (id) => {
		return this.Client.get(
			ENDPOINTS.EQUIPMENT_TYPES.CONTROL_UNITS.DOWNLOAD_SOFTWARE(id)
		).then((response) => response);
	};

	delete_software = (id) => {
		return this.Client.delete(
			ENDPOINTS.EQUIPMENT_TYPES.CONTROL_UNITS.DELETE_SOFTWARE(id)
		).then((response) => response);
	};
}

export default ControlUnitTypesAPI;

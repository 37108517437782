// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ControlUnitsAPI {
	constructor(Client, PlainClient, config) {
		this.Client = Client;
		this.PlainClient = PlainClient;
		this.config = config;
	}

	list = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT.CONTROL_UNITS.LIST, {
			params,
		}).then((response) => response.data);
	};

	list_reports = (id, params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT.CONTROL_UNITS.REPORTS(id), {
			params,
		}).then((response) => response.data);
	};

	get_by_id = (id) => {
		return this.Client.get(
			ENDPOINTS.EQUIPMENT.CONTROL_UNITS.GET_BY_ID(id)
		).then((response) => response.data.data);
	};

	get_liveview = (id) => {
		return this.PlainClient.get(
			ENDPOINTS.EQUIPMENT.CONTROL_UNITS.LIVE_VIEW(id)
		).then((response) => response.data);
	};

	store = (data) => {
		return this.Client.post(ENDPOINTS.EQUIPMENT.CONTROL_UNITS.STORE, data).then(
			(response) => response.data.data
		);
	};

	update = (id, data) => {
		return this.Client.put(
			ENDPOINTS.EQUIPMENT.CONTROL_UNITS.UPDATE(id),
			data
		).then((response) => response.data.data);
	};

	delete = (data) => {
		return this.Client.delete(
			ENDPOINTS.EQUIPMENT.CONTROL_UNITS.DELETE(data)
		).then((response) => response);
	};

	update_software = (id, data) => {
		return this.Client.post(
			ENDPOINTS.EQUIPMENT.CONTROL_UNITS.UPDATE_SOFTWARE(id),
			data
		).then((response) => response);
	};

	download_software = (id) => {
		return this.Client.get(
			ENDPOINTS.EQUIPMENT.CONTROL_UNITS.DOWNLOAD_SOFTWARE(id)
		).then((response) => response);
	};
}

export default ControlUnitsAPI;

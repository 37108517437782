export const COUNTRIES = [
  { name: 'Afghanistan (AF)', value: 'AF' },
  { name: 'Åland Islands (AX)', value: 'AX' },
  { name: 'Albania (AL)', value: 'AL' },
  { name: 'Algeria (DZ)', value: 'DZ' },
  { name: 'American Samoa (AS)', value: 'AS' },
  { name: 'AndorrA (AD)', value: 'AD' },
  { name: 'Angola (AO)', value: 'AO' },
  { name: 'Anguilla (AI)', value: 'AI' },
  { name: 'Antarctica (AQ)', value: 'AQ' },
  { name: 'Antigua and Barbuda (AG)', value: 'AG' },
  { name: 'Argentina (AR)', value: 'AR' },
  { name: 'Armenia (AM)', value: 'AM' },
  { name: 'Aruba (AW)', value: 'AW' },
  { name: 'Australia (AU)', value: 'AU' },
  { name: 'Austria (AT)', value: 'AT' },
  { name: 'Azerbaijan (AZ)', value: 'AZ' },
  { name: 'Bahamas (BS)', value: 'BS' },
  { name: 'Bahrain (BH)', value: 'BH' },
  { name: 'Bangladesh (BD)', value: 'BD' },
  { name: 'Barbados (BB)', value: 'BB' },
  { name: 'Belarus (BY)', value: 'BY' },
  { name: 'Belgium (BE)', value: 'BE' },
  { name: 'Belize (BZ)', value: 'BZ' },
  { name: 'Benin (BJ)', value: 'BJ' },
  { name: 'Bermuda (BM)', value: 'BM' },
  { name: 'Bhutan (BT)', value: 'BT' },
  { name: 'Bolivia (BO)', value: 'BO' },
  { name: 'Bosnia and Herzegovina (BA)', value: 'BA' },
  { name: 'Botswana (BW)', value: 'BW' },
  { name: 'Bouvet Island (BV)', value: 'BV' },
  { name: 'Brazil (BR)', value: 'BR' },
  { name: 'British Indian Ocean Territory (IO)', value: 'IO' },
  { name: 'Brunei Darussalam (BN)', value: 'BN' },
  { name: 'Bulgaria (BG)', value: 'BG' },
  { name: 'Burkina Faso (BF)', value: 'BF' },
  { name: 'Burundi (BI)', value: 'BI' },
  { name: 'Cambodia (KH)', value: 'KH' },
  { name: 'Cameroon (CM)', value: 'CM' },
  { name: 'Canada (CA)', value: 'CA' },
  { name: 'Cape Verde (CV)', value: 'CV' },
  { name: 'Cayman Islands (KY)', value: 'KY' },
  { name: 'Central African Republic (CF)', value: 'CF' },
  { name: 'Chad (TD)', value: 'TD' },
  { name: 'Chile (CL)', value: 'CL' },
  { name: 'China (CN)', value: 'CN' },
  { name: 'Christmas Island (CX)', value: 'CX' },
  { name: 'Cocos (Keeling) Islands (CC)', value: 'CC' },
  { name: 'Colombia (CO)', value: 'CO' },
  { name: 'Comoros (KM)', value: 'KM' },
  { name: 'Congo (CG)', value: 'CG' },
  { name: 'Congo, The Democratic Republic of the (CD)', value: 'CD' },
  { name: 'Cook Islands (CK)', value: 'CK' },
  { name: 'Costa Rica (CR)', value: 'CR' },
  { name: "Cote D'Ivoire (CI)", value: 'CI' },
  { name: 'Croatia (HR)', value: 'HR' },
  { name: 'Cuba (CU)', value: 'CU' },
  { name: 'Cyprus (CY)', value: 'CY' },
  { name: 'Czech Republic (CZ)', value: 'CZ' },
  { name: 'Denmark (DK)', value: 'DK' },
  { name: 'Djibouti (DJ)', value: 'DJ' },
  { name: 'Dominica (DM)', value: 'DM' },
  { name: 'Dominican Republic (DO)', value: 'DO' },
  { name: 'Ecuador (EC)', value: 'EC' },
  { name: 'Egypt (EG)', value: 'EG' },
  { name: 'El Salvador (SV)', value: 'SV' },
  { name: 'Equatorial Guinea (GQ)', value: 'GQ' },
  { name: 'Eritrea (ER)', value: 'ER' },
  { name: 'Estonia (EE)', value: 'EE' },
  { name: 'Ethiopia (ET)', value: 'ET' },
  { name: 'Falkland Islands (Malvinas) (FK)', value: 'FK' },
  { name: 'Faroe Islands (FO)', value: 'FO' },
  { name: 'Fiji (FJ)', value: 'FJ' },
  { name: 'Finland (FI)', value: 'FI' },
  { name: 'France (FR)', value: 'FR' },
  { name: 'French Guiana (GF)', value: 'GF' },
  { name: 'French Polynesia (PF)', value: 'PF' },
  { name: 'French Southern Territories (TF)', value: 'TF' },
  { name: 'Gabon (GA)', value: 'GA' },
  { name: 'Gambia (GM)', value: 'GM' },
  { name: 'Georgia (GE)', value: 'GE' },
  { name: 'Germany (DE)', value: 'DE' },
  { name: 'Ghana (GH)', value: 'GH' },
  { name: 'Gibraltar (GI)', value: 'GI' },
  { name: 'Greece (GR)', value: 'GR' },
  { name: 'Greenland (GL)', value: 'GL' },
  { name: 'Grenada (GD)', value: 'GD' },
  { name: 'Guadeloupe (GP)', value: 'GP' },
  { name: 'Guam (GU)', value: 'GU' },
  { name: 'Guatemala (GT)', value: 'GT' },
  { name: 'Guernsey (GG)', value: 'GG' },
  { name: 'Guinea (GN)', value: 'GN' },
  { name: 'Guinea-Bissau (GW)', value: 'GW' },
  { name: 'Guyana (GY)', value: 'GY' },
  { name: 'Haiti (HT)', value: 'HT' },
  { name: 'Heard Island and Mcdonald Islands (HM)', value: 'HM' },
  { name: 'Holy See (Vatican City State) (VA)', value: 'VA' },
  { name: 'Honduras (HN)', value: 'HN' },
  { name: 'Hong Kong (HK)', value: 'HK' },
  { name: 'Hungary (HU)', value: 'HU' },
  { name: 'Iceland (IS)', value: 'IS' },
  { name: 'India (IN)', value: 'IN' },
  { name: 'Indonesia (ID)', value: 'ID' },
  { name: 'Iran, Islamic Republic Of (IR)', value: 'IR' },
  { name: 'Iraq (IQ)', value: 'IQ' },
  { name: 'Ireland (IE)', value: 'IE' },
  { name: 'Isle of Man (IM)', value: 'IM' },
  { name: 'Israel (IL)', value: 'IL' },
  { name: 'Italy (IT)', value: 'IT' },
  { name: 'Jamaica (JM)', value: 'JM' },
  { name: 'Japan (JP)', value: 'JP' },
  { name: 'Jersey (JE)', value: 'JE' },
  { name: 'Jordan (JO)', value: 'JO' },
  { name: 'Kazakhstan (KZ)', value: 'KZ' },
  { name: 'Kenya (KE)', value: 'KE' },
  { name: 'Kiribati (KI)', value: 'KI' },
  { name: "Korea, Democratic People'S Republic of (KP)", value: 'KP' },
  { name: 'Korea, Republic of (KR)', value: 'KR' },
  { name: 'Kuwait (KW)', value: 'KW' },
  { name: 'Kyrgyzstan (KG)', value: 'KG' },
  { name: "Lao People'S Democratic Republic (LA)", value: 'LA' },
  { name: 'Latvia (LV)', value: 'LV' },
  { name: 'Lebanon (LB)', value: 'LB' },
  { name: 'Lesotho (LS)', value: 'LS' },
  { name: 'Liberia (LR)', value: 'LR' },
  { name: 'Libyan Arab Jamahiriya (LY)', value: 'LY' },
  { name: 'Liechtenstein (LI)', value: 'LI' },
  { name: 'Lithuania (LT)', value: 'LT' },
  { name: 'Luxembourg (LU)', value: 'LU' },
  { name: 'Macao (MO)', value: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of (MK)', value: 'MK' },
  { name: 'Madagascar (MG)', value: 'MG' },
  { name: 'Malawi (MW)', value: 'MW' },
  { name: 'Malaysia (MY)', value: 'MY' },
  { name: 'Maldives (MV)', value: 'MV' },
  { name: 'Mali (ML)', value: 'ML' },
  { name: 'Malta (MT)', value: 'MT' },
  { name: 'Marshall Islands (MH)', value: 'MH' },
  { name: 'Martinique (MQ)', value: 'MQ' },
  { name: 'Mauritania (MR)', value: 'MR' },
  { name: 'Mauritius (MU)', value: 'MU' },
  { name: 'Mayotte (YT)', value: 'YT' },
  { name: 'Mexico (MX)', value: 'MX' },
  { name: 'Micronesia, Federated States of (FM)', value: 'FM' },
  { name: 'Moldova, Republic of (MD)', value: 'MD' },
  { name: 'Monaco (MC)', value: 'MC' },
  { name: 'Mongolia (MN)', value: 'MN' },
  { name: 'Montserrat (MS)', value: 'MS' },
  { name: 'Morocco (MA)', value: 'MA' },
  { name: 'Mozambique (MZ)', value: 'MZ' },
  { name: 'Myanmar (MM)', value: 'MM' },
  { name: 'Namibia (NA)', value: 'NA' },
  { name: 'Nauru (NR)', value: 'NR' },
  { name: 'Nepal (NP)', value: 'NP' },
  { name: 'Netherlands (NL)', value: 'NL' },
  { name: 'Netherlands Antilles (AN)', value: 'AN' },
  { name: 'New Caledonia (NC)', value: 'NC' },
  { name: 'New Zealand (NZ)', value: 'NZ' },
  { name: 'Nicaragua (NI)', value: 'NI' },
  { name: 'Niger (NE)', value: 'NE' },
  { name: 'Nigeria (NG)', value: 'NG' },
  { name: 'Niue (NU)', value: 'NU' },
  { name: 'Norfolk Island (NF)', value: 'NF' },
  { name: 'Northern Mariana Islands (MP)', value: 'MP' },
  { name: 'Norway (NO)', value: 'NO' },
  { name: 'Oman (OM)', value: 'OM' },
  { name: 'Pakistan (PK)', value: 'PK' },
  { name: 'Palau (PW)', value: 'PW' },
  { name: 'Palestinian Territory, Occupied (PS)', value: 'PS' },
  { name: 'Panama (PA)', value: 'PA' },
  { name: 'Papua New Guinea (PG)', value: 'PG' },
  { name: 'Paraguay (PY)', value: 'PY' },
  { name: 'Peru (PE)', value: 'PE' },
  { name: 'Philippines (PH)', value: 'PH' },
  { name: 'Pitcairn (PN)', value: 'PN' },
  { name: 'Poland (PL)', value: 'PL' },
  { name: 'Portugal (PT)', value: 'PT' },
  { name: 'Puerto Rico (PR)', value: 'PR' },
  { name: 'Qatar (QA)', value: 'QA' },
  { name: 'Reunion (RE)', value: 'RE' },
  { name: 'Romania (RO)', value: 'RO' },
  { name: 'Russian Federation (RU)', value: 'RU' },
  { name: 'RWANDA (RW)', value: 'RW' },
  { name: 'Saint Helena (SH)', value: 'SH' },
  { name: 'Saint Kitts and Nevis (KN)', value: 'KN' },
  { name: 'Saint Lucia (LC)', value: 'LC' },
  { name: 'Saint Pierre and Miquelon (PM)', value: 'PM' },
  { name: 'Saint Vincent and the Grenadines (VC)', value: 'VC' },
  { name: 'Samoa (WS)', value: 'WS' },
  { name: 'San Marino (SM)', value: 'SM' },
  { name: 'Sao Tome and Principe (ST)', value: 'ST' },
  { name: 'Saudi Arabia (SA)', value: 'SA' },
  { name: 'Senegal (SN)', value: 'SN' },
  { name: 'Serbia and Montenegro (CS)', value: 'CS' },
  { name: 'Seychelles (SC)', value: 'SC' },
  { name: 'Sierra Leone (SL)', value: 'SL' },
  { name: 'Singapore (SG)', value: 'SG' },
  { name: 'Slovakia (SK)', value: 'SK' },
  { name: 'Slovenia (SI)', value: 'SI' },
  { name: 'Solomon Islands (SB)', value: 'SB' },
  { name: 'Somalia (SO)', value: 'SO' },
  { name: 'South Africa (ZA)', value: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands (GS)', value: 'GS' },
  { name: 'Spain (ES)', value: 'ES' },
  { name: 'Sri Lanka (LK)', value: 'LK' },
  { name: 'Sudan (SD)', value: 'SD' },
  { name: 'Suriname (SR)', value: 'SR' },
  { name: 'Svalbard and Jan Mayen (SJ)', value: 'SJ' },
  { name: 'Swaziland (SZ)', value: 'SZ' },
  { name: 'Sweden (SE)', value: 'SE' },
  { name: 'Switzerland (CH)', value: 'CH' },
  { name: 'Syrian Arab Republic (SY)', value: 'SY' },
  { name: 'Taiwan, Province of China (TW)', value: 'TW' },
  { name: 'Tajikistan (TJ)', value: 'TJ' },
  { name: 'Tanzania, United Republic of (TZ)', value: 'TZ' },
  { name: 'Thailand (TH)', value: 'TH' },
  { name: 'Timor-Leste (TL)', value: 'TL' },
  { name: 'Togo (TG)', value: 'TG' },
  { name: 'Tokelau (TK)', value: 'TK' },
  { name: 'Tonga (TO)', value: 'TO' },
  { name: 'Trinidad and Tobago (TT)', value: 'TT' },
  { name: 'Tunisia (TN)', value: 'TN' },
  { name: 'Turkey (TR)', value: 'TR' },
  { name: 'Turkmenistan (TM)', value: 'TM' },
  { name: 'Turks and Caicos Islands (TC)', value: 'TC' },
  { name: 'Tuvalu (TV)', value: 'TV' },
  { name: 'Uganda (UG)', value: 'UG' },
  { name: 'Ukraine (UA)', value: 'UA' },
  { name: 'United Arab Emirates (AE)', value: 'AE' },
  { name: 'United Kingdom (GB)', value: 'GB' },
  { name: 'United States (US)', value: 'US' },
  { name: 'United States Minor Outlying Islands (UM)', value: 'UM' },
  { name: 'Uruguay (UY)', value: 'UY' },
  { name: 'Uzbekistan (UZ)', value: 'UZ' },
  { name: 'Vanuatu (VU)', value: 'VU' },
  { name: 'Venezuela (VE)', value: 'VE' },
  { name: 'Viet Nam (VN)', value: 'VN' },
  { name: 'Virgin Islands, British (VG)', value: 'VG' },
  { name: 'Virgin Islands, U.S. (VI)', value: 'VI' },
  { name: 'Wallis and Futuna (WF)', value: 'WF' },
  { name: 'Western Sahara (EH)', value: 'EH' },
  { name: 'Yemen (YE)', value: 'YE' },
  { name: 'Zambia (ZM)', value: 'ZM' },
  { name: 'Zimbabwe (ZW)', value: 'ZW' },
];

export const COUNTRIES_LIST = {
  AF: 'Afghanistan (AF)',
  AX: 'Åland Islands (AX)',
  AL: 'Albania (AL)',
  DZ: 'Algeria (DZ)',
  AS: 'American Samoa (AS)',
  AD: 'AndorrA (AD)',
  AO: 'Angola (AO)',
  AI: 'Anguilla (AI)',
  AQ: 'Antarctica (AQ)',
  AG: 'Antigua and Barbuda (AG)',
  AR: 'Argentina (AR)',
  AM: 'Armenia (AM)',
  AW: 'Aruba (AW)',
  AU: 'Australia (AU)',
  AT: 'Austria (AT)',
  AZ: 'Azerbaijan (AZ)',
  BS: 'Bahamas (BS)',
  BH: 'Bahrain (BH)',
  BD: 'Bangladesh (BD)',
  BB: 'Barbados (BB)',
  BY: 'Belarus (BY)',
  BE: 'Belgium (BE)',
  BZ: 'Belize (BZ)',
  BJ: 'Benin (BJ)',
  BM: 'Bermuda (BM)',
  BT: 'Bhutan (BT)',
  BO: 'Bolivia (BO)',
  BA: 'Bosnia and Herzegovina (BA)',
  BW: 'Botswana (BW)',
  BV: 'Bouvet Island (BV)',
  BR: 'Brazil (BR)',
  IO: 'British Indian Ocean Territory (IO)',
  BN: 'Brunei Darussalam (BN)',
  BG: 'Bulgaria (BG)',
  BF: 'Burkina Faso (BF)',
  BI: 'Burundi (BI)',
  KH: 'Cambodia (KH)',
  CM: 'Cameroon (CM)',
  CA: 'Canada (CA)',
  CV: 'Cape Verde (CV)',
  KY: 'Cayman Islands (KY)',
  CF: 'Central African Republic (CF)',
  TD: 'Chad (TD)',
  CL: 'Chile (CL)',
  CN: 'China (CN)',
  CX: 'Christmas Island (CX)',
  CC: 'Cocos (Keeling) Islands (CC)',
  CO: 'Colombia (CO)',
  KM: 'Comoros (KM)',
  CG: 'Congo (CG)',
  CD: 'Congo, The Democratic Republic of the (CD)',
  CK: 'Cook Islands (CK)',
  CR: 'Costa Rica (CR)',
  CI: "Cote D'Ivoire (CI)",
  HR: 'Croatia (HR)',
  CU: 'Cuba (CU)',
  CY: 'Cyprus (CY)',
  CZ: 'Czech Republic (CZ)',
  DK: 'Denmark (DK)',
  DJ: 'Djibouti (DJ)',
  DM: 'Dominica (DM)',
  DO: 'Dominican Republic (DO)',
  EC: 'Ecuador (EC)',
  EG: 'Egypt (EG)',
  SV: 'El Salvador (SV)',
  GQ: 'Equatorial Guinea (GQ)',
  ER: 'Eritrea (ER)',
  EE: 'Estonia (EE)',
  ET: 'Ethiopia (ET)',
  FK: 'Falkland Islands (Malvinas) (FK)',
  FO: 'Faroe Islands (FO)',
  FJ: 'Fiji (FJ)',
  FI: 'Finland (FI)',
  FR: 'France (FR)',
  GF: 'French Guiana (GF)',
  PF: 'French Polynesia (PF)',
  TF: 'French Southern Territories (TF)',
  GA: 'Gabon (GA)',
  GM: 'Gambia (GM)',
  GE: 'Georgia (GE)',
  DE: 'Germany (DE)',
  GH: 'Ghana (GH)',
  GI: 'Gibraltar (GI)',
  GR: 'Greece (GR)',
  GL: 'Greenland (GL)',
  GD: 'Grenada (GD)',
  GP: 'Guadeloupe (GP)',
  GU: 'Guam (GU)',
  GT: 'Guatemala (GT)',
  GG: 'Guernsey (GG)',
  GN: 'Guinea (GN)',
  GW: 'Guinea-Bissau (GW)',
  GY: 'Guyana (GY)',
  HT: 'Haiti (HT)',
  HM: 'Heard Island and Mcdonald Islands (HM)',
  VA: 'Holy See (Vatican City State) (VA)',
  HN: 'Honduras (HN)',
  HK: 'Hong Kong (HK)',
  HU: 'Hungary (HU)',
  IS: 'Iceland (IS)',
  IN: 'India (IN)',
  ID: 'Indonesia (ID)',
  IR: 'Iran, Islamic Republic Of (IR)',
  IQ: 'Iraq (IQ)',
  IE: 'Ireland (IE)',
  IM: 'Isle of Man (IM)',
  IL: 'Israel (IL)',
  IT: 'Italy (IT)',
  JM: 'Jamaica (JM)',
  JP: 'Japan (JP)',
  JE: 'Jersey (JE)',
  JO: 'Jordan (JO)',
  KZ: 'Kazakhstan (KZ)',
  KE: 'Kenya (KE)',
  KI: 'Kiribati (KI)',
  KP: "Korea, Democratic People's Republic of (KP)",
  KR: 'Korea, Republic of (KR)',
  KW: 'Kuwait (KW)',
  KG: "Kyrgyzstan Lao People'S Democratic Republic (KG)",
  LV: 'Latvia (LV)',
  LB: 'Lebanon (LB)',
  LS: 'Lesotho (LS)',
  LR: 'Liberia (LR)',
  LY: 'Libyan Arab Jamahiriya (LY)',
  LI: 'Liechtenstein (LI)',
  LT: 'Lithuania (LT)',
  LU: 'Luxembourg (LU)',
  MO: 'Macao (MO)',
  MK: 'Macedonia, The Former Yugoslav Republic of (MK)',
  MG: 'Madagascar (MG)',
  MW: 'Malawi (MW)',
  MY: 'Malaysia (MY)',
  MV: 'Maldives (MV)',
  ML: 'Mali (ML)',
  MT: 'Malta (MT)',
  MH: 'Marshall Islands (MH)',
  MQ: 'Martinique (MQ)',
  MR: 'Mauritania (MR)',
  MU: 'Mauritius (MU)',
  YT: 'Mayotte (YT)',
  MX: 'Mexico (MX)',
  FM: 'Micronesia, Federated States of (FM)',
  MD: 'Moldova, Republic of (MD)',
  MC: 'Monaco (MC)',
  MN: 'Mongolia (MN)',
  MS: 'Montserrat (MS)',
  MA: 'Morocco (MA)',
  MZ: 'Mozambique (MZ)',
  MM: 'Myanmar (MM)',
  NA: 'Namibia (NA)',
  NR: 'Nauru (NR)',
  NP: 'Nepal (NP)',
  NL: 'Netherlands (NL)',
  AN: 'Netherlands Antilles (AN)',
  NC: 'New Caledonia (NC)',
  NZ: 'New Zealand (NZ)',
  NI: 'Nicaragua (NI)',
  NE: 'Niger (NE)',
  NG: 'Nigeria (NG)',
  NU: 'Niue (NU)',
  NF: 'Norfolk Island (NF)',
  MP: 'Northern Mariana Islands (MP)',
  NO: 'Norway (NO)',
  OM: 'Oman (OM)',
  PK: 'Pakistan (PK)',
  PW: 'Palau (PW)',
  PS: 'Palestinian Territory, Occupied (PS)',
  PA: 'Panama (PA)',
  PG: 'Papua New Guinea (PG)',
  PY: 'Paraguay (PY)',
  PE: 'Peru (PE)',
  PH: 'Philippines (PH)',
  PN: 'Pitcairn (PN)',
  PL: 'Poland (PL)',
  PT: 'Portugal (PT)',
  PR: 'Puerto Rico (PR)',
  QA: 'Qatar (QA)',
  RE: 'Reunion (RE)',
  RO: 'Romania (RO)',
  RU: 'Russian Federation (RU)',
  RW: 'RWANDA (RW)',
  SH: 'Saint Helena (SH)',
  KN: 'Saint Kitts and Nevis (KN)',
  LC: 'Saint Lucia (LC)',
  PM: 'Saint Pierre and Miquelon (PM)',
  VC: 'Saint Vincent and the Grenadines (VC)',
  WS: 'Samoa (WS)',
  SM: 'San Marino (SM)',
  ST: 'Sao Tome and Principe (ST)',
  SA: 'Saudi Arabia (SA)',
  SN: 'Senegal (SN)',
  CS: 'Serbia and Montenegro (CS)',
  SC: 'Seychelles (SC)',
  SL: 'Sierra Leone (SL)',
  SG: 'Singapore (SG)',
  SK: 'Slovakia (SK)',
  SI: 'Slovenia (SI)',
  SB: 'Solomon Islands (SB)',
  SO: 'Somalia (SO)',
  ZA: 'South Africa (ZA)',
  GS: 'South Georgia and the South Sandwich Islands (GS)',
  ES: 'Spain (ES)',
  LK: 'Sri Lanka (LK)',
  SD: 'Sudan (SD)',
  SR: 'Suriname (SR)',
  SJ: 'Svalbard and Jan Mayen (SJ)',
  SZ: 'Swaziland (SZ)',
  SE: 'Sweden (SE)',
  CH: 'Switzerland (CH)',
  SY: 'Syrian Arab Republic (SY)',
  TW: 'Taiwan, Province of China (TW)',
  TJ: 'Tajikistan (TJ)',
  TZ: 'Tanzania, United Republic of (TZ)',
  TH: 'Thailand (TH)',
  TL: 'Timor-Leste (TL)',
  TG: 'Togo (TG)',
  TK: 'Tokelau (TK)',
  TO: 'Tonga (TO)',
  TT: 'Trinidad and Tobago (TT)',
  TN: 'Tunisia (TN)',
  TR: 'Turkey (TR)',
  TM: 'Turkmenistan (TM)',
  TC: 'Turks and Caicos Islands (TC)',
  TV: 'Tuvalu (TV)',
  UG: 'Uganda (UG)',
  UA: 'Ukraine (UA)',
  AE: 'United Arab Emirates (AE)',
  GB: 'United Kingdom (GB)',
  US: 'United States (US)',
  UM: 'United States Minor Outlying Islands (UM)',
  UY: 'Uruguay (UY)',
  UZ: 'Uzbekistan (UZ)',
  VU: 'Vanuatu (VU)',
  VE: 'Venezuela (VE)',
  VN: 'Viet Nam (VN)',
  VG: 'Virgin Islands, British (VG)',
  VI: 'Virgin Islands, U.S. (VI)',
  WF: 'Wallis and Futuna (WF)',
  EH: 'Western Sahara (EH)',
  YE: 'Yemen (YE)',
  ZM: 'Zambia (ZM)',
  ZW: 'Zimbabwe (ZW)',
};

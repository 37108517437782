// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ErpAPI {
  constructor(Client, config) {
    this.Client = Client;
    this.config = config;
  }

  listCompanies = (params) => {
    return this.Client.get(ENDPOINTS.ERP.LIST_COMPANIES, { params }).then(
      (response) => response.data
    );
  };

  listContracts = (params) => {
    return this.Client.get(ENDPOINTS.ERP.LIST_CONTRACTS, { params }).then(
      (response) => response.data
    );
  };

  getContract = (id) => {
    return this.Client.get(ENDPOINTS.ERP.GET_CONTRACT(id)).then(
      (response) => response.data
    );
  };

  store = (data) => {
    return this.Client.post(ENDPOINTS.ERP.IMPORT, data).then(
      (response) => response.data
    );
  };

  syncCompany = (companyId) => {
    return this.Client.put(ENDPOINTS.ERP.SYNC_COMPANY(companyId)).then(
      (response) => response.data
    );
  };
}

export default ErpAPI;

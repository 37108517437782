import { ENDPOINTS } from '@constants';

export class AlertsAPI {
  constructor(Client, config) {
    this.Client = Client;
    this.config = config;
  }

  get_open_alerts = (params) => {
    return this.Client.get(ENDPOINTS.ALERTS.OPEN.GET, { params }).then(
      (response) => response.data
    );
  };

  resolve_open_alert = (id, solution) => {
    return this.Client.put(ENDPOINTS.ALERTS.OPEN.RESOLVE_ALERT(id), {
      solution,
    }).then((response) => response.data);
  };

  get_resolved_alerts = (params) => {
    return this.Client.get(ENDPOINTS.ALERTS.RESOLVED.GET, { params }).then(
      (response) => response.data
    );
  };

  get_by_id = (id) => {
    return this.Client.get(ENDPOINTS.ALERTS.OPEN.GET_BY_ID(id)).then(
      (response) => response.data
    );
  };
}

// Imports => Constants
import { ENDPOINTS } from '@constants';

export class TransfersAPI {
  constructor(Client, UploadClient, DownloadClient, config) {
    this.Client = Client;
    this.UploadClient = UploadClient;
    this.DownloadClient = DownloadClient;
    this.config = config;
  }

  list = (project_id, params) => {
    return this.Client.get(ENDPOINTS.PROJECTS.TRANSFERS.INDEX(project_id), {
      params,
    }).then((response) => {
      return response.data;
    });
  };

  download_xls = (project_id, transfer_id, params) => {
    return this.Client.get(
      ENDPOINTS.PROJECTS.TRANSFERS.DOWNLOAD.XLS(project_id, transfer_id),
      { params }
    ).then((response) => response);
  };

  download_control_unit_file = (project_id, transfer_id, params) => {
    return this.Client.get(
      ENDPOINTS.PROJECTS.TRANSFERS.DOWNLOAD.CONTROL_UNIT(
        project_id,
        transfer_id
      ),
      { params }
    ).then((response) => response);
  };
}

export default TransfersAPI;

// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ContractsAPI {
  constructor(Client, config) {
    this.Client = Client;
    this.config = config;
  }

  list = (params) => {
    return this.Client.get(ENDPOINTS.CONTRACTS.LIST, { params }).then(
      (response) => response.data
    );
  };

  list_configurations = (id, params) => {
    return this.Client.get(ENDPOINTS.CONTRACTS.CONFIGURATIONS(id), {
      params,
    }).then((response) => response.data);
  };

  get_by_id = (id) => {
    return this.Client.get(ENDPOINTS.CONTRACTS.GET_BY_ID(id)).then(
      (response) => response.data.data
    );
  };

  store = (data) => {
    return this.Client.post(ENDPOINTS.CONTRACTS.STORE, data).then(
      (response) => response.data.data
    );
  };

  update = (id, data) => {
    return this.Client.put(ENDPOINTS.CONTRACTS.UPDATE(id), data).then(
      (response) => response.data.data
    );
  };

  delete = (data) => {
    return this.Client.delete(ENDPOINTS.CONTRACTS.DELETE(data)).then(
      (response) => response
    );
  };

  add_equipment = (id, data) => {
    return this.Client.post(ENDPOINTS.CONTRACTS.ADD_EQUIPMENT(id), data).then(
      (response) => response.data.data
    );
  };

  remove_equipment = (id, data) => {
    return this.Client.delete(ENDPOINTS.CONTRACTS.REMOVE_EQUIPMENT(id), {
      data,
    }).then((response) => response.data.data);
  };

  notifications = () => {
    return this.Client.get(ENDPOINTS.CONTRACTS.NOTIFICATIONS).then(
      (response) => response.data.data
    );
  };

  dismiss = (id) => {
    return this.Client.delete(ENDPOINTS.CONTRACTS.DISMISS(id)).then(
      (response) => response
    );
  };

  updateExpirationDate = (id, data) => {
    return this.Client.post(
      ENDPOINTS.CONTRACTS.EQUIPMENTS.EXP_DATE(id),
      data
    ).then((response) => response.data.data);
  };

  getOrderList = (id) =>
    this.Client.get(ENDPOINTS.CONTRACTS.DETAILS(id)).then(
      (response) => response.data.data
    );
}

export default ContractsAPI;

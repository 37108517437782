// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ConfigurationsAPI {
	constructor(Client, DownloadClient, config) {
		this.Client = Client;
		this.DownloadClient = DownloadClient;
		this.config = config;
	}

	list = (params) => {
		return this.Client.get(ENDPOINTS.CONFIGURATIONS.LIST, { params }).then(
			(response) => response.data
		);
	};

	get_by_id = (id) => {
		return this.Client.get(ENDPOINTS.CONFIGURATIONS.GET_BY_ID(id)).then(
			(response) => response.data.data
		);
	};

	store = (data, params) => {
		return this.Client.post(ENDPOINTS.CONFIGURATIONS.STORE, data, {
			params,
		}).then((response) => response.data.data);
	};

	update = (id, data, params) => {
		return this.Client.put(ENDPOINTS.CONFIGURATIONS.UPDATE(id), data, {
			params,
		}).then((response) => response.data.data);
	};

	delete = (data) => {
		return this.Client.delete(ENDPOINTS.CONFIGURATIONS.DELETE(data)).then(
			(response) => response
		);
	};

	download = (id) => {
		return this.DownloadClient.get(ENDPOINTS.CONFIGURATIONS.DOWNLOAD(id)).then(
			(response) => response
		);
	};

	send_to_control_unit = (id, project_id) => {
		return this.Client.get(
			ENDPOINTS.CONFIGURATIONS.SEND_TO_CONTROL_UNIT(id, project_id)
		).then((response) => response);
	};

	add_operator = (id, data) => {
		return this.Client.put(ENDPOINTS.CONFIGURATIONS.ADD_USER(id), data).then(
			(response) => response.data.data
		);
	};

	remove_operator = (id, data) => {
		return this.Client.put(ENDPOINTS.CONFIGURATIONS.REMOVE_USER(id), data).then(
			(response) => response.data.data
		);
	};

	add_equipment = (id, data) => {
		return this.Client.put(
			ENDPOINTS.CONFIGURATIONS.ADD_EQUIPMENT(id),
			data
		).then((response) => response.data.data);
	};

	remove_equipment = (id, data) => {
		return this.Client.put(
			ENDPOINTS.CONFIGURATIONS.REMOVE_EQUIPMENT(id),
			data
		).then((response) => response.data.data);
	};
}

export default ConfigurationsAPI;

// Imports => Constants
import { ENDPOINTS } from '@constants';

export class PileListAPI {
  constructor(Client, UploadClient, DownloadClient, config) {
    this.Client = Client;
    this.UploadClient = UploadClient;
    this.DownloadClient = DownloadClient;
    this.config = config;
  }

  list = (project_id) => {
    return this.Client.get(ENDPOINTS.PROJECTS.PILELIST.INDEX(project_id)).then(
      (response) => response.data
    );
  };

  get_by_id = (project_id, pilelist_id, params) => {
    return this.Client.get(
      ENDPOINTS.PROJECTS.PILELIST.SHOW(project_id, pilelist_id),
      { params }
    ).then((response) => response.data);
  };

  get_piles_by_project_id = (project_id) => {
    return this.Client.get(
      ENDPOINTS.PROJECTS.PILELIST.ALL_PILES(project_id)
    ).then((response) => response.data);
  };

  delete_pilelist = (project_id, pilelist_id) => {
    return this.Client.delete(
      ENDPOINTS.PROJECTS.PILELIST.DELETE(project_id, pilelist_id)
    ).then((response) => response.data);
  };

  list_sendable_devices = (project_id, pilelist_id) => {
    return this.Client.get(
      ENDPOINTS.PROJECTS.PILELIST.SENDABLE_DEVICES(project_id, pilelist_id)
    ).then((response) => response.data);
  };

  // Just the import for now
  send_to_control_unit = (project_id, pilelist_id, data) => {
    return this.Client.post(
      ENDPOINTS.PROJECTS.PILELIST.SEND_TO_DEVICE(project_id, pilelist_id),
      data
    ).then((response) => response.data);
  };

  store = (project_id, data) => {
    return this.UploadClient.post(
      ENDPOINTS.PROJECTS.PILELIST.STORE(project_id),
      data
    ).then((response) => response.data);
  };

  // Just the import for now
  update = (project_id, pilelist_id, data) => {
    return this.UploadClient.post(
      ENDPOINTS.PROJECTS.PILELIST.UPDATE(project_id, pilelist_id),
      data
    ).then((response) => response.data);
  };

  transfers = {
    list: (project_id) => {
      return this.Client.get(
        ENDPOINTS.PROJECTS.PILELIST.TRANSFERS.INDEX(project_id)
      ).then((response) => {
        return response.data;
      });
    },
  };

  download_xls = (project_id, pilelist_id, params) => {
    return this.DownloadClient.get(
      ENDPOINTS.PROJECTS.PILELIST.DOWNLOAD.XLS(project_id, pilelist_id),
      { params }
    ).then((response) => response);
  };

  download_control_unit_file = (project_id, pilelist_id, params) => {
    return this.DownloadClient.get(
      ENDPOINTS.PROJECTS.PILELIST.DOWNLOAD.CONTROL_UNIT(
        project_id,
        pilelist_id
      ),
      { params }
    ).then((response) => response);
  };

  validate_piles = (project_id, pilelist_id, pile_id, data) => {
    return this.Client.patch(
      ENDPOINTS.PROJECTS.PILELIST.PILES.VALIDATE(
        project_id,
        pilelist_id,
        pile_id
      ),
      data
    ).then((response) => response.data);
  };

  update_piles = (project_id, pilelist_id, data) => {
    return this.Client.patch(
      ENDPOINTS.PROJECTS.PILELIST.PILES.PATCH(project_id, pilelist_id),
      data
    ).then((response) => response.data);
  };

  delete_piles = (project_id, pilelist_id, params) => {
    return this.Client.delete(
      ENDPOINTS.PROJECTS.PILELIST.PILES.DELETE(project_id, pilelist_id),
      { params }
    ).then((response) => response);
  };
}

export default PileListAPI;

// Imports => React
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => SCSS
import '@styles/index.scss';
import Favicon from '@assets/images/favicon.ico';

// Imports => Constants
import {
  AUTHENTICATION_ROUTES,
  DASHBOARD_ROUTES,
  DEFAULT_ROUTE,
  KEYS,
  PERMISSIONS,
  ROLES,
  ROUTES,
  TITLES,
} from '@constants';

// Imports => Utilities
import { AcIsArray, AcIsSet, AcSetDocumentTitle } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
const AcToasterHoc = loadable(() =>
  import('@molecules/ac-toaster-hoc/ac-toaster-hoc.web')
);
const AcNoInternetConnectionNoticeModal = loadable(() =>
  import(
    '@molecules/ac-no-internet-connection-notice-modal/ac-no-internet-connection-notice-modal.web'
  )
);
const AcKeyboardShortcutsModal = loadable(() =>
  import(
    '@molecules/ac-keyboard-shortcuts-modal/ac-keyboard-shortcuts-modal.web'
  )
);
const AcDrawer = loadable(() => import('@molecules/ac-drawer/ac-drawer.web'));

const AcModal = loadable(() => import('@molecules/ac-modal/ac-modal.web'));
const AcConfirmationModal = loadable(() =>
  import('@molecules/ac-confirmation-modal/ac-confirmation-modal.web')
);
const AcMultiSelectModal = loadable(() =>
  import('@molecules/ac-multi-select-modal/ac-multi-select-modal.web')
);
const AcResourceLinkedToModal = loadable(() =>
  import(
    '@molecules/ac-resource-linked-to-modal/ac-resource-linked-to-modal.web'
  )
);
const AcTimeoutNoticeModal = loadable(() =>
  import('@molecules/ac-timeout-notice-modal/ac-timeout-notice-modal.web')
);
const AcNewWindow = loadable(() =>
  import('@molecules/ac-new-window/ac-new-window.web')
);

// Imports => Components
const AcHeader = loadable(() => import('@components/ac-header/ac-header.web'));
const AcNavigation = loadable(() =>
  import('@components/ac-navigation/ac-navigation.web')
);
const AcActivityMonitor = loadable(() =>
  import('@components/ac-activity-monitor/ac-activity-monitor.web')
);
const AcImpersonatingNotice = loadable(() =>
  import('@components/ac-impersonating-notice/ac-impersonating-notice.web')
);
const AcGeneralNotice = loadable(() =>
  import('@components/ac-general-notice/ac-general-notice.web')
);

// Imports => Atoms
const AcErrorBoundary = loadable(() =>
  import('@atoms/ac-error-boundary/ac-error-boundary.web')
);
const AcPrivateRoute = loadable(() =>
  import('@atoms/ac-private-route/ac-private-route.web')
);
const AcScrollHOC = loadable(() =>
  import('@atoms/ac-scroll-hoc/ac-scroll-hoc.web')
);
const AcAuthBackground = loadable(() =>
  import('@atoms/ac-auth-background/ac-auth-background.web')
);

const _CLASSES = {
  ROOT: 'ac-root',
  MAIN: 'ac-app',
  NAVIGATION_VISIBLE: 'ac-app--navigation-visible',
  NAVIGATION_RENDERED: 'ac-app--no-navigation',
  IMPERSONATING: 'ac-app--impersonating',
  GENERAL_NOTICE: 'ac-app--general-notice',
  ROUTE: {
    SECTION: 'ac-route__section',
  },
};

const konami = {
  pattern: [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a',
  ],
  current: 0,
};

const App = ({ store }) => {
  const $monitor = useRef(null);
  const $navigation = useRef(null);

  const location = useLocation();
  const { can, cannot, is } = usePermissions();

  const [isOnline, setIsOnline] = useState(navigator && navigator.onLine);

  const {
    auth: {
      is_authorized,
      is_impersonating,
      current_impersonated,
      current_permissions,
      current_account_id,
      current_roles,
    },
    profile,
    contracts,
    ui,
    freshContentIsAvailable,
  } = store;

  useEffect(() => {
    handleRouteChanged();
    addEvents();

    return () => removeEvents();
  }, [location, $navigation?.current]);

  useEffect(() => {
    if (!isOnline) displayNoInternetConnectionNoticeModal();
    else {
      ui.reset(KEYS.MODAL).then(() => {
        // window.location.reload(false);
      });
    }
  }, [isOnline]);

  const handleRouteChanged = (event) => {
    ui.setValue(KEYS.SUB_NAVIGATION, KEYS.VISIBLE, false);
    ui.setValue(KEYS.MESSAGE_CENTER, KEYS.VISIBLE, false);
    ui.setValue(KEYS.SUPPORT_CENTER, KEYS.VISIBLE, false);
    ui.setValue(KEYS.DRAWER, KEYS.VISIBLE, false);

    if (
      ui.current_modal &&
      ui.current_modal.type &&
      ui.current_modal.type !== 'timeout'
    ) {
      ui.reset(KEYS.MODAL);
    }

    const isAuthRoute = AUTHENTICATION_ROUTES.find(
      (item) => location.pathname.indexOf(item) > -1
    );

    if (is_authorized && !isAuthRoute) {
      addEvents();
      profile.who_am_i();

      if ($monitor && $monitor.current) $monitor.current.restart();
      if (can(PERMISSIONS.CONTRACT.READ)) contracts.list_notifications();
      else contracts.reset(KEYS.NOTIFICATIONS);
    } else {
      if ($monitor && $monitor.current) $monitor.current.stop();
    }
  };

  const handleInternetConnectionChanged = () => {
    if (navigator && AcIsSet(navigator.onLine)) {
      setIsOnline(navigator.onLine);
    }
  };

  const addEvents = () => {
    removeEvents().then(() => {
      document.body.addEventListener('keyup', handleKeyUp, { passive: true });
      document.body.addEventListener('keydown', handleKonami, {
        passive: true,
      });
      window.addEventListener('online', handleInternetConnectionChanged, {
        passive: true,
      });
      window.addEventListener('offline', handleInternetConnectionChanged, {
        passive: true,
      });
    });
  };

  const removeEvents = () => {
    return new Promise((resolve) => {
      document.body.removeEventListener('keyup', handleKeyUp);
      document.body.removeEventListener('keydown', handleKonami, {
        passive: true,
      });
      window.removeEventListener('online', handleInternetConnectionChanged, {
        passive: true,
      });
      window.removeEventListener('offline', handleInternetConnectionChanged, {
        passive: true,
      });

      resolve();
    });
  };

  const displayNoInternetConnectionNoticeModal = async (event) => {
    if (!is_authorized) return;

    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.NO_INTERNET_CONNECTION,
      body: <AcNoInternetConnectionNoticeModal />,
      closeable: false,
      centered: true,
      visible: true,
      actions: [],
      callback: async () => {
        await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const displayKeyboardShortcuts = async (event) => {
    if (!is_authorized) return;

    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.KEYBOARD_SHORTCUTS,
      body: <AcKeyboardShortcutsModal />,
      closeable: true,
      visible: true,
      actions: [],
      callback: async () => {
        await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const hammerTime = () => {
    const $mchammer = document.getElementById('mc-hammer');

    if ($mchammer) $mchammer.setAttribute('data-show', true);

    const HammerTimeMP3 = require('@assets/dumm/stop.mp3');
    const audio = new Audio(HammerTimeMP3);

    audio.loop = false;
    audio.addEventListener(
      'ended',
      () => {
        if ($mchammer) $mchammer.removeAttribute('data-show');
      },
      { passive: true }
    );
    audio.play();
  };

  const handleKonami = (event) => {
    const key = event.key || event.which;

    const $active_element = document.activeElement;
    const $inputs = ['input', 'select', 'button', 'textarea'];

    if (
      !$active_element ||
      $inputs.indexOf($active_element.tagName.toLowerCase()) === -1
    ) {
      // If the key isn't in the pattern, or isn't the current key in the pattern, reset
      if (
        konami.pattern.indexOf(key) < 0 ||
        key !== konami.pattern[konami.current]
      ) {
        konami.current = 0;
        return;
      }

      // Update how much of the pattern is complete
      konami.current++;

      // If complete, alert and reset
      if (konami.pattern.length === konami.current) {
        konami.current = 0;
        hammerTime();
      }
    }
  };

  const handleKeyUp = async (event) => {
    const key = event.key || event.which;

    const $active_element = document.activeElement;
    const $inputs = ['input', 'select', 'button', 'textarea'];

    if (
      !$active_element ||
      $inputs.indexOf($active_element.tagName.toLowerCase()) === -1
    ) {
      if (key) {
        if (key === '?' || (key === 191 && shift)) {
          if (!ui.current_modal.visible) displayKeyboardShortcuts();
        } else {
          switch (key) {
            case '/':
            case 191:
              const $query = document.querySelector('input[name="query"]');
              if (AcIsSet($query) && $query.focus) $query.focus();
              break;

            case '[':
            case 219:
              if (!ui.current_modal.visible) {
                const state = ui[KEYS.CURRENT_NAVIGATION][KEYS.VISIBLE];
                await ui.setValue(KEYS.NAVIGATION, KEYS.VISIBLE, !state);
                if (!state)
                  await ui.setValue(KEYS.NAVIGATION, KEYS.EXPANDED, []);
              }
              break;

            case ']':
            case 221:
              if (!ui.current_modal.visible) {
                await ui.setValue(KEYS.NAVIGATION, KEYS.VISIBLE, false);
                await ui.setValue(KEYS.NAVIGATION, KEYS.EXPANDED, []);
              }
              break;

            case 'c':
            case 67:
              if (!ui.current_modal.visible) {
                const $button = document.querySelector(
                  'button[rel="ac-add-button"]'
                );
                if (AcIsSet($button) && $button.click) $button.click();
              }
              break;

            case 'e':
            case 69:
              if (!ui.current_modal.visible) {
                const $edit = document.querySelector(
                  'i[class*="ac-details-card__edit"]'
                );
                if (AcIsSet($edit) && $edit.click) $edit.click();
              }
              break;

            default:
          }
        }
      }
    }
  };

  const impersonatingIsReady = useMemo(() => {
    if (!is_impersonating) return false;
    if (!AcIsSet(current_impersonated)) return false;
    if (!AcIsSet(profile.current_profile)) return false;

    const { id } = profile.current_profile;
    const { user_id } = current_impersonated;

    return id === user_id;
  }, [is_impersonating, current_impersonated, profile.current_profile]);

  const authorized = useMemo(() => {
    const notAnAuthorizationRoute =
      AUTHENTICATION_ROUTES.indexOf(location.pathname) === -1;

    return is_authorized && notAnAuthorizationRoute;
  }, [is_authorized, location]);

  const getRouteSectionClassNames = useMemo(() => {
    return clsx(_CLASSES.ROUTE.SECTION);
  }, []);

  const getMainClassNames = useMemo(() => {
    return clsx(
      _CLASSES.MAIN,
      impersonatingIsReady && _CLASSES.IMPERSONATING,
      freshContentIsAvailable && _CLASSES.GENERAL_NOTICE,
      ui.current_navigation.visible && _CLASSES.NAVIGATION_VISIBLE,
      !ui.current_navigation.rendered && _CLASSES.NAVIGATION_RENDERED
    );
  }, [
    ui.current_navigation.visible,
    ui.current_navigation.rendered,
    impersonatingIsReady,
    freshContentIsAvailable,
  ]);

  useEffect(() => {}, []);

  const displayTimeoutNoticeModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    window.FaviconNotification.add();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.SESSION_TIMEOUT,
      type: 'timeout',
      body: (
        <AcTimeoutNoticeModal
          checkActivity={$monitor && $monitor.current && $monitor.current.check}
          callback={async () => {
            window.FaviconNotification.remove();
            if ($monitor && $monitor.current) $monitor.current.restart();
            await ui.reset(KEYS.MODAL);
          }}
          update={() => {
            ui.setValue(KEYS.MODAL, KEYS.TITLE, TITLES.SESSION_EXPIRED);
          }}
        />
      ),
      centered: true,
      closeable: false,
      visible: true,
      actions: [],
      callback: async () => {
        await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const renderDrawer = useMemo(() => {
    return (
      <AcDrawer key={ui.current_drawer.id} visible={ui.current_drawer.visible}>
        {ui.current_drawer.children}
      </AcDrawer>
    );
  }, [
    ui.current_drawer,
    ui.current_drawer.id,
    ui.current_drawer.visible,
    ui.current_drawer.children,
  ]);

  const renderNewWindow = useMemo(() => {
    if (!AcIsSet(ui.current_new_window)) return null;
    if (!AcIsSet(ui.current_new_window.options)) return null;
    if (!AcIsSet(ui.current_new_window.body)) return null;

    const { options, body } = ui.current_new_window;

    return (
      <AcNewWindow key={options.id} {...options}>
        {body}
      </AcNewWindow>
    );
  }, [
    ui.current_new_window,
    ui.current_new_window?.options,
    ui.current_new_window?.body,
  ]);

  const renderModal = useMemo(() => {
    const { current_modal } = ui;

    let body = ui.current_modal.body;

    if (AcIsSet(current_modal.tag) && current_modal.tag === KEYS.CONFIRM) {
      body = <AcConfirmationModal {...current_modal.props} />;
    } else if (
      AcIsSet(current_modal.tag) &&
      current_modal.tag === KEYS.MULTI_SELECT
    ) {
      body = <AcMultiSelectModal {...current_modal.props} />;
    } else if (
      AcIsSet(current_modal.tag) &&
      current_modal.tag === KEYS.RESOURCE_LINKED_TO
    ) {
      body = <AcResourceLinkedToModal {...current_modal.props} />;
    }

    if (AcIsArray(body) && body.length === 0) return null;

    return (
      <AcModal {...ui.current_modal} callback={ui.current_modal.callback}>
        {body}
      </AcModal>
    );
  }, [
    ui.current_modal,
    ui.current_modal.body,
    ui.current_modal.title,
    ui.current_modal.closeable,
    ui.current_modal.visible,
    ui.current_modal.progress,
  ]);

  const renderToasterHoc = useMemo(() => {
    return (
      <AcToasterHoc
        queue={store.toasters.queue}
        callback={store.toasters.remove}
      />
    );
  }, [store.toasters, store.toasters.queue]);

  const renderDefaultRoute = useMemo(() => {
    return (
      <Route
        key={`default-route-${DEFAULT_ROUTE.id}`}
        path={'*'}
        element={
          <AcPrivateRoute
            name={DEFAULT_ROUTE.name}
            path={'/'}
            component={DEFAULT_ROUTE.component}
            forbidden={DEFAULT_ROUTE.forbidden}
            authorized={is_authorized}
          />
        }
      />
    );
  }, [is_authorized]);

  const renderRoutes = useMemo(() => {
    const collection = ROUTES;
    let result = [];
    let key;

    for (key in collection) {
      const item = collection[key];
      const { forbidden, allowed } = item;
      if (forbidden && !is_authorized) continue;
      else if (!can(allowed)) continue;
      else if (current_permissions) {
        const object = (
          <Route
            key={`route-${item.id}`}
            path={item.path}
            element={
              <AcPrivateRoute
                name={item.name}
                path={item.path}
                component={item.component}
                forbidden={item.forbidden}
                authorized={is_authorized}
              />
            }
          />
        );
        result.push(object);
      }
    }

    return result;
  }, [is_authorized, current_permissions]);

  return (
    <AcErrorBoundary screen={location.pathname}>
      <AcScrollHOC>
        <AcAuthBackground />
        <main className={getMainClassNames} key={authorized}>
          <section id={KEYS.SCROLLER} className={getRouteSectionClassNames}>
            <TransitionGroup component={null} appear>
              <CSSTransition
                key={location.key}
                timeout={400}
                classNames={'page-fade'}
                unmountOnExit
              >
                <Routes location={location}>
                  {renderDefaultRoute}
                  {renderRoutes}
                </Routes>
              </CSSTransition>
            </TransitionGroup>
          </section>

          {authorized && ui.current_navigation.rendered && (
            <AcNavigation ref={$navigation} store={store} />
          )}

          {authorized && <AcImpersonatingNotice />}

          {authorized && freshContentIsAvailable && <AcGeneralNotice />}

          {authorized && ui.current_navigation.rendered && (
            <AcHeader
              withFreshContentNotice={freshContentIsAvailable}
              withMessageCenter
              withNavigation
            />
          )}

          {renderDrawer}

          {renderModal}

          {renderNewWindow}

          {renderToasterHoc}

          {authorized && (
            <AcActivityMonitor
              ref={$monitor}
              callback={displayTimeoutNoticeModal}
              authorized={authorized}
            />
          )}
        </main>
      </AcScrollHOC>

      <div id={'mc-hammer'} />
    </AcErrorBoundary>
  );
};

export default withStore(observer(App));

// Imports => Dependencies
import dayjs from 'dayjs';
import axios from 'axios';

// Imports => Config
import config from '@config';

// Imports => Constants
import { ENDPOINTS, KEYS } from '@constants';

// Imports => Utilities
import { AcIsSet, AcGetState, AcSaveState } from '@utils';

let busy = { [KEYS.ACCOUNT]: false, [KEYS.IMPERSONATED]: false };

export const AcTokenRefresher = (client, callback) => {
  const expires_in = dayjs().add(2, 'minutes').format('X'); // add 10 minutes to 'now'

  const impersonated = AcGetState(KEYS.IMPERSONATED);
  const user = AcGetState(KEYS.ACCOUNT);

  let account = user;
  let type = KEYS.ACCOUNT;

  if (AcIsSet(impersonated)) {
    account = impersonated;
    type = KEYS.IMPERSONATED;
  }

  return AcRefreshIfRequired(expires_in, account, type)
    .then(() => {})
    .catch((error) => {
      // Token refresh failed, call the provided callback if given...
      if (callback) callback();
    });
};

const AcRefreshIfRequired = (expires, user, type) => {
  return new Promise((resolve, reject) => {
    if (busy[type]) {
      resolve();
      return;
    }
    busy[type] = true;

    if (user && user.expires_at > expires) {
      busy[type] = false;
      resolve();
    } else if (user && user.expires_at < expires && user.refresh_token) {
      const data = {
        refresh_token: user.refresh_token,
        grant_type: 'refresh_token',
        client_secret: process.env.CLIENT_SECRET,
        client_id: process.env.CLIENT_ID,
      };

      axios
        .post(`${config.apiUrl}${ENDPOINTS.OAUTH.REFRESH}`, data)
        .then((response) => {
          const { data } = response;

          const access_token = data.access_token;
          const expires_in = data.expires_in;
          const expires_at = dayjs().add(expires_in, 'seconds').format('X');
          let account = data;

          if (account.permissions) {
            const len = account.permissions.length;
            let n = 0;
            let formatted = {};

            for (n; n < len; n++) {
              const item = account.permissions[n];
              formatted[item.toUpperCase()] = true;
            }

            account.permissions = formatted;
          }

          account.expires_at = expires_at;

          AcSaveState(KEYS.EXPIRES_IN, expires_in);
          AcSaveState(KEYS.EXPIRES_AT, expires_at);

          if (type === KEYS.IMPERSONATED)
            AcSaveState(KEYS.IMPERSONATED_ACCESS_TOKEN, access_token);
          else if (type === KEYS.ACCOUNT)
            AcSaveState(KEYS.ACCESS_TOKEN, access_token);

          AcSaveState(type, account);

          busy[type] = false;
          resolve();
        })
        .catch((error) => reject(error));
    }
  });
};

// Imports => Constants
import { ENDPOINTS } from '@constants';

export class HammersAPI {
	constructor(Client, config) {
		this.Client = Client;
		this.config = config;
	}

	list = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT.HAMMERS.LIST, { params }).then(
			(response) => response.data
		);
	};

	table = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT.HAMMERS.TABLE, { params }).then(
			(response) => response.data
		);
	};

	get_by_id = (id) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT.HAMMERS.GET_BY_ID(id)).then(
			(response) => response.data.data
		);
	};

	store = (data) => {
		return this.Client.post(ENDPOINTS.EQUIPMENT.HAMMERS.STORE, data).then(
			(response) => response.data.data
		);
	};

	update = (id, data) => {
		return this.Client.put(ENDPOINTS.EQUIPMENT.HAMMERS.UPDATE(id), data).then(
			(response) => response.data.data
		);
	};

	delete = (data) => {
		return this.Client.delete(ENDPOINTS.EQUIPMENT.HAMMERS.DELETE(data)).then(
			(response) => response
		);
	};
}

export default HammersAPI;

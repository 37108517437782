// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ReportsAPI {
  constructor(Client, DownloadClient, config) {
    this.Client = Client;
    this.DownloadClient = DownloadClient;
    this.config = config;
  }

  download = (id, params) => {
    return this.DownloadClient.get(
      `/api/reports/${id}/download?format=csv`
    ).then((response) => response.data);
  };

  pdfLink = (hash) => {
    return this.Client.get(`/api/reports/${hash}/link`).then(
      (response) => response.data
    );
  };
}

export default ReportsAPI;
